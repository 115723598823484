import React from "react";

/**
 * Paste in your SVG logo and return it from this component.
 * Make sure you have a height set for your logo.
 * It is recommended to keep the height within 25-35px.
 */
export default function Logo() {
  return (
    <svg width="127" height="28" viewBox="0 0 127 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0)">
      <path d="M45.6893 8.02332H45.6851L42.9685 15.2763H42.9007L40.1712 8.02332H36.0327V20.2104H39.1556V13.1478H39.2233L40.7721 17.1298L41.9696 20.2104H43.8867L44.2337 19.3176L46.633 13.1478H46.7007V20.2104H49.8278V8.02332H45.6893Z" fill="#3F31EF"/>
      <path d="M79.589 12.7924L78.3661 12.5723C77.1516 12.3523 76.6269 12.0899 76.6269 11.5398C76.6269 10.9897 77.1346 10.5496 78.1291 10.5496C79.0813 10.5496 79.826 10.9559 79.8894 11.6583C79.8938 11.6964 79.9234 11.7218 79.9571 11.7218H83.114C83.1562 11.7218 83.1902 11.6879 83.1858 11.6456C83.131 9.28435 81.3112 7.80328 78.0825 7.80328C75.1838 7.80328 73.0723 9.34783 73.0723 11.7979C73.0723 13.6895 74.3968 14.9167 76.8215 15.3441L78.0444 15.5641C79.4113 15.8095 79.8853 16.055 79.8853 16.6305C79.8853 17.2313 79.225 17.6714 78.1545 17.6714C77.1642 17.6714 76.3306 17.2694 76.1656 16.567C76.1571 16.5374 76.1317 16.512 76.0979 16.512H72.9325C72.8903 16.512 72.8566 16.5458 72.8607 16.5881C72.9114 19.034 74.985 20.4135 78.1121 20.4135C81.4762 20.4135 83.4355 18.8182 83.4355 16.3089C83.4397 14.3411 82.2083 13.2663 79.589 12.7924Z" fill="#3F31EF"/>
      <path d="M109.041 8.02332H105.605C105.567 8.02332 105.533 8.05717 105.533 8.09527V20.1427C105.533 20.1808 105.567 20.2147 105.605 20.2147H109.041C109.079 20.2147 109.113 20.1808 109.113 20.1427V8.09527C109.113 8.05294 109.079 8.02332 109.041 8.02332Z" fill="#3F31EF"/>
      <path d="M126.31 15.2933H123.018C122.98 15.2933 122.95 15.3229 122.95 15.3567C122.819 16.732 121.931 17.608 120.716 17.608C119.231 17.608 118.308 16.3089 118.308 14.1042C118.308 11.9249 119.239 10.6258 120.708 10.6258C121.931 10.6258 122.824 11.5187 122.933 12.8686C122.938 12.9066 122.967 12.9363 123.001 12.9363H126.293C126.336 12.9363 126.37 12.9024 126.365 12.8601C126.319 9.87678 123.898 7.81598 120.708 7.81598C116.984 7.81598 114.661 10.0968 114.661 14.1169C114.661 18.1369 116.975 20.4262 120.708 20.4262C123.966 20.4262 126.327 18.3739 126.382 15.3737C126.382 15.3313 126.349 15.2933 126.31 15.2933Z" fill="#3F31EF"/>
      <path d="M96.2488 8.02332H91.8606C91.831 8.02332 91.8014 8.04448 91.7929 8.07408L87.8617 20.1215C87.8449 20.1681 87.883 20.2147 87.9294 20.2147H90.9213C91.2513 20.2147 91.5391 19.9946 91.6194 19.673L93.9343 11.0955C93.9554 11.0235 94.0527 11.0235 94.0738 11.0955L96.4478 20.1554C96.4563 20.185 96.4859 20.2104 96.5155 20.2104H100.18C100.231 20.2104 100.265 20.1639 100.248 20.1173L96.3165 8.06986C96.3081 8.04025 96.2784 8.02332 96.2488 8.02332Z" fill="#3F31EF"/>
      <path d="M65.0405 9.18697C58.9216 5.32772 52.4557 11.7937 56.3151 17.9126C56.6027 18.3696 56.9922 18.7589 57.4491 19.0467C63.568 22.9059 70.034 16.44 66.1748 10.3211C65.887 9.86404 65.4977 9.47473 65.0405 9.18697ZM59.2264 16.3342C59.1587 16.2792 59.091 16.2115 59.0318 16.1438C56.5689 13.1732 60.2927 9.44512 63.2676 11.9079C63.3353 11.9672 63.403 12.0306 63.4622 12.1026C65.9209 15.0647 62.1969 18.7886 59.2264 16.3342Z" fill="#3F31EF"/>
      <path d="M27.082 2.5246H20.6558C20.4023 2.5246 20.1968 2.73011 20.1968 2.98361V25.0164C20.1968 25.2699 20.4023 25.4754 20.6558 25.4754H27.082C27.3355 25.4754 27.541 25.2699 27.541 25.0164V2.98361C27.541 2.73011 27.3355 2.5246 27.082 2.5246Z" fill="#3F31EF"/>
      <path d="M0.424577 24.663L4.42275 18.0946C4.60269 17.7989 5.03265 17.8012 5.20944 18.0987L9.11241 24.6671C9.29423 24.9733 9.07371 25.3607 8.71782 25.3607H0.816669C0.45857 25.3607 0.238384 24.9689 0.424577 24.663Z" fill="#3F31EF"/>
      <path d="M16.7589 13.2888L13.4897 19.4309C13.3165 19.7563 12.8498 19.7552 12.6782 19.4289L4.1408 3.19729C3.98002 2.89163 4.20168 2.5246 4.54705 2.5246H11.4185C11.5939 2.5246 11.7539 2.62446 11.8309 2.78192L16.766 12.8715C16.8307 13.0037 16.828 13.1589 16.7589 13.2888Z" fill="#3F31EF"/>
      </g>
      <defs>
      <clipPath id="clip0">
      <rect width="126.459" height="28" fill="white"/>
      </clipPath>
      </defs>
    </svg>

  );
}
